import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getReportSubscriptions',
    path: () => '/shop/config/subscriptions',
  })
  .post({
    action: 'addReportSubscription',
    path: () => '/shop/config/subscriptions',
  })
  .put({
    action: 'updateReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}`,
  })
  .put({
    action: 'toggleReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}/send`,
    requestConfig: {
      responseType: 'blob',
    },
  })
  .delete({
    action: 'removeReportSubscription',
    path: ({ id }) => `/shop/config/subscriptions/${id}`,
  })
  .getStore();

store.getters = {
};

export default store;

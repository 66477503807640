import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getOrders',
    path: '/shop/orders',
  })
  .post({
    action: 'getOrdersReport',
    requestConfig: {
      responseType: 'blob',
    },
    path: '/shop/orders/report',
  })
  .post({
    action: 'updateOrders',
    path: () => '/shop/orders',
  })
  .getStore();

export default store;

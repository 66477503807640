import axios from '@/api/axios';
import lang from '@/lang';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  // .put({
  //   action: 'reportDelivery',
  //   path: '/shop/orders/delivered',
  // })
  // .get({
  //   action: 'getPayerOffer',
  //   path: () => '/shop/orders/offer',
  // })
  // .get({
  //   action: 'getPayeeOffer',
  //   path: () => '/shop/orders/offer/all',
  // })
  .get({
    action: 'getProducts',
    property: 'products',
    path: ({ catalogId }) => `/shop/${catalogId}/products`,
  })
  .post({
    action: 'addProduct',
    path: ({ catalogId }) => `/shop/${catalogId}/products`,
  })
  .put({
    action: 'updateProduct',
    path: ({ catalogId, productId }) => `/shop/${catalogId}products/${productId}`,
  })
  .delete({
    action: 'removeProduct',
    path: ({ catalogId, productId }) => `/shop/${catalogId}/products/${productId}`,
  })
  .post({
    action: 'addProductVariant',
    path: ({ catalogId, productId }) => `/shop/${catalogId}/products/${productId}/variants`,
  })
  .put({
    action: 'updateProductVariant',
    path: ({ catalogId, productId, variantId }) => `/shop/${catalogId}/products/${productId}/variants/${variantId}`,
  })
  .delete({
    action: 'removeProductVariant',
    path: ({ catalogId, productId, variantId }) => `/shop/${catalogId}/products/${productId}/variants/${variantId}`,
  })
  .post({
    action: 'validateShopImport',
    path: () => '/shop/import/validate',
  })
  .post({
    action: 'uploadShopImport',
    path: () => '/shop/import',
  })
  .getStore();

store.getters = {
  products: (s) => s.products,
  ordersReportTypes: () => [
    {
      type: 'DailyOrders',
      icon: 'far fa-user-tag',
      text: lang.t('shop.reports.type.dailyOrders'),
    },
    {
      type: 'DailyProductSetSummary',
      icon: 'far fa-boxes-stacked',
      text: lang.t('shop.reports.type.dailyProductSetSummary'),
    },
  ],
};

export default store;

import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .put({
    action: 'reportDelivery',
    path: '/shop/orders/delivered',
  })
  .get({
    action: 'getPayerOffer',
    path: () => '/shop/orders/offer',
  })
  .get({
    action: 'getPayeeOffer',
    path: () => '/shop/orders/offer/all',
  })
  .get({
    action: 'getProductSets',
    path: '/shop/product-sets',
  })
  .get({
    action: 'getProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .post({
    action: 'addProductSet',
    path: '/shop/product-sets',
  })
  .put({
    action: 'updateProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .delete({
    action: 'removeProductSet',
    path: ({ productSetId }) => `/shop/product-sets/${productSetId}`,
  })
  .post({
    action: 'addProductSetAvailability',
    path: '/shop/product-sets/availability',
  })
  .get({
    action: 'getProductSetAvailabilities',
    path: '/shop/product-sets/availability',
  })
  .delete({
    action: 'removeProductSetAvailability',
    path: ({ availabilityId }) => `/shop/product-sets/availability/${availabilityId}`,
  })
  .get({
    action: 'getProductSetAvailability',
    path: ({ availabilityId }) => `/shop/product-sets/availability/${availabilityId}`,
  })
  .put({
    action: 'toggleShopScopeType',
    path: () => '/shop/config/scope-type',
  })
  .put({
    action: 'toggleShopOrderAutoCancel',
    path: () => '/shop/config/order-auto-cancel',
  })
  .post({
    action: 'addProductSetType',
    path: () => '/shop/config/types',
  })
  .put({
    action: 'updateProductSetType',
    path: ({ id }) => `/shop/config/types/${id}`,
  })
  .delete({
    action: 'removeProductSetType',
    path: ({ id }) => `/shop/config/types/${id}`,
  })
  .getStore();

export default store;
